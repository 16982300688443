export const SET_SELECTED_VEIV = "SET_SELECTED_VEIV";
export const RESET_VIEVS = "RESET_VIEVS";

export const setSelectedViev = (seelectedVeiv) => ({
	type: SET_SELECTED_VEIV,
	payload: seelectedVeiv,
});

export const resetVievs = {
	type: RESET_VIEVS,
};
