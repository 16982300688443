import request from "../../helpers/request";
import { addSpinner, removeSpinner, timeoutShowTask } from "./index";

export const GET_ALL_IMPORTANT_MATTERS_ITEMS = "GET_ALL_IMPORTANT_MATTERS_ITEMS";
export const ADD_IMPORTANT_MATTERS_ITEM = "ADD_IMPORTANT_MATTERS_ITEM";
export const EDIT_IMPORTANT_MATTERS_ITEM = "EDIT_IMPORTANT_MATTERS_ITEM";
export const DELETE_IMPORTANT_MATTERS_ITEM = "DELETE_IMPORTANT_MATTERS_ITEM";

export const getAllImportantMattersItems = () => async (dispatch) => {
	dispatch(addSpinner());
	const { data, status } = await request.get("important-matters", {
		headers: { "Content-Type": "application/json" },
		credentials: "include",
		withCredentials: true,
	});

	if (status === 200) {
		dispatch(removeSpinner());
		dispatch({
			type: GET_ALL_IMPORTANT_MATTERS_ITEMS,
			payload: data.data,
		});
	} else if (status === 404) {
		dispatch(removeSpinner());
		dispatch(timeoutShowTask("Nie ma żadnych elemetów do wyświetlenia"));
	} else {
		dispatch(removeSpinner());
		dispatch(timeoutShowTask(data.message || data.error));
	}
};

export const addImportantMattersItem = (mainInfoItemData) => async (dispatch) => {
	dispatch(addSpinner());
	const { data, status } = await request.post("important-matters", mainInfoItemData, {
		headers: { "Content-Type": "application/json" },
		credentials: "include",
		withCredentials: true,
	});

	if (status === 201) {
		dispatch(removeSpinner());
		dispatch({
			type: ADD_IMPORTANT_MATTERS_ITEM,
			payload: data.data,
		});
	} else {
		dispatch(removeSpinner());
		dispatch(timeoutShowTask(data.message || data.error));
	}
};

export const editImportantMattersItem = (mainInfoItemData) => async (dispatch) => {
	dispatch(addSpinner());
	const { data, status } = await request.put(`important-matters/${mainInfoItemData.id}`, mainInfoItemData, {
		headers: { "Content-Type": "application/json" },
		credentials: "include",
		withCredentials: true,
	});

	if (status === 200) {
		dispatch(removeSpinner());
		dispatch({
			type: EDIT_IMPORTANT_MATTERS_ITEM,
			payload: data.data[0],
		});
	} else {
		dispatch(removeSpinner());
		dispatch(timeoutShowTask(data.message || data.error));
	}
};

export const deleteImportantMattersItem = (id) => async (dispatch) => {
	dispatch(addSpinner());
	const { data, status } = await request.delete(`important-matters/${id}`, {
		headers: { "Content-Type": "application/json" },
		credentials: "include",
		withCredentials: true,
	});

	if (status === 200) {
		dispatch(removeSpinner());
		dispatch({
			type: DELETE_IMPORTANT_MATTERS_ITEM,
			payload: id,
		});
	} else {
		dispatch(removeSpinner());
		dispatch(timeoutShowTask(data.message || data.error));
	}
};
