import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllMainInfoItems, getAllSectionInfoItems } from "../../data/actions";
import { AddItem, Button, Item } from "../../components";
import styled from "styled-components";

const StyledWrapper = styled.div`
	width: 90%;
	margin: 50px auto 20px auto;
	h2 {
		margin: 20px auto;
		color: ${(props) => props.theme.colors.box};
	}
	.items {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		margin-top: 30px;
	}
	.buttons {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
`;

const MainInfoViev = () => {
	const mainInfo = useSelector((store) => store.mainInfo);
	const sectionInfo = useSelector((store) => store.sectionInfo);
	const switchViev = useSelector((store) => store.switchViev);
	const dispatch = useDispatch();

	const [isAddItemModalOpen, setIsAddItemModalOpen] = useState(false);

	const handleOpenAddItemModal = () => {
		setIsAddItemModalOpen(true);
	};

	const infoTitle = switchViev === "main-info" ? "Ogłoszenia" : "Sukcesy";

	const mainInfoItems =
		switchViev === "main-info"
			? !mainInfo.length
				? !sectionInfo.length
				: mainInfo.reverse().map((item) => {
						return (
							<Item
								key={item._id}
								id={item._id}
								content={item.content}
								date={item.date}
								title={item.title}
								signature={item.signature}
							/>
						);
				  })
			: !sectionInfo.length
			? !sectionInfo.length
			: sectionInfo.reverse().map((item) => {
					return (
						<Item
							key={item._id}
							id={item._id}
							content={item.content}
							content1={item.content1}
							date={item.date}
							title={item.title}
						/>
					);
			  });

	useEffect(() => {
		if (switchViev === "main-info") {
			if (!mainInfo.length) {
				dispatch(getAllMainInfoItems());
			} else {
				return;
			}
		} else if (switchViev === "section-info") {
			if (!sectionInfo.length) {
				dispatch(getAllSectionInfoItems());
			} else {
				return;
			}
		}
	}, [mainInfo, sectionInfo, switchViev, dispatch]);

	const navigate = useNavigate();

	const handleGoToStart = () => {
		navigate("/");
	};

	return (
		<StyledWrapper>
			<h2>{infoTitle}</h2>
			<div className="buttons">
				<Button name="wstecz" type="button" onClick={handleGoToStart} />
				<Button name="dodaj" type="button" onClick={handleOpenAddItemModal} />
			</div>
			<div className="items">{mainInfoItems}</div>
			<AddItem isAddItemModalOpen={isAddItemModalOpen} setIsAddItemModalOpen={setIsAddItemModalOpen} />
		</StyledWrapper>
	);
};

export default React.memo(MainInfoViev);
