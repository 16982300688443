import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setSelectedViev } from "../../data/actions";
import { Button, DeleteConfirmation, AddNewsItem } from "../../components";
import styled from "styled-components";

const StyledWrapper = styled.div`
	margin: 20px auto;
	width: 90%;
	border: 1px solid ${(props) => props.theme.colors.box};
	border-radius: ${(props) => props.theme.radius};
	padding: 10px;
	h3 {
		text-align: center;
		margin-bottom: 20px;
		span {
			color: ${(props) => props.theme.colors.box};
		}
	}
	.interior {
		display: flex;
		justify-content: space-between;
		align-items: center;

		img {
			width: 120px;
			height: 100px;
			border-radius: ${(props) => props.theme.radius};
		}
		div {
			flex-basis: 50%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			p {
				margin-bottom: 10px;
			}
			button {
				margin-bottom: 20px;
			}
		}
	}

	.element {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		margin-bottom: 20px;
		border-bottom: 1px solid black;
		p {
			text-align: left;
		}
		p:nth-child(1) {
			span {
				font-weight: 700;
			}
		}
	}

	.signature {
		display: flex;
		p:nth-child(1) {
			margin-right: 20px;
			span {
				font-weight: 700;
			}
		}
	}

	/* p:nth-child(3) {
		padding-bottom: 20px;
		padding-top: 20px;
		border-bottom: 1px solid ${(props) => props.theme.colors.box};
		border-top: 1px solid ${(props) => props.theme.colors.box};
	}
	p:nth-child(4) {
		padding-bottom: 20px;
		padding-top: 20px;
		border-bottom: 1px solid ${(props) => props.theme.colors.box};
	}
	p:nth-child(5) {
		padding-top: 20px;
	} */
	@media screen and (min-width: ${(props) => props.theme.breakpoints.medium}) {
		.interior {
			padding-bottom: 20px;
			div {
				flex-direction: row;
				flex-basis: 70%;
			}
		}
	}
	@media screen and (min-width: ${(props) => props.theme.breakpoints.xLarge}) {
		flex-basis: 45%;
	}
`;

const NewsItem = ({ item }) => {
	const { _id, photo, title, content1, content2, date, signature } = item;

	const dispatch = useDispatch();

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isEditNewsItemModalOpen, setIsEditNewsItemModalOpen] = useState(false);
	const [isEdited, setIsEdited] = useState(false);

	const handleOpenDeleteModal = () => {
		dispatch(setSelectedViev("news"));
		setIsDeleteModalOpen(true);
	};

	const hendleOpenEditNewsModal = () => {
		setIsEdited({ _id, title, content1, content2, date, signature });
		setIsEditNewsItemModalOpen(true);
	};

	const splitedConten1 = content1.split(/\n/);
	const vievSplitedContent1 = splitedConten1.map((item, index) => <p key={index}>{item}</p>);

	const splitedConten2 = !content2 ? [] : content2.split(/\n/);
	const vievSplitedContent2 = splitedConten2.map((item, index) => <p key={index}>{item}</p>);

	return (
		<StyledWrapper>
			<h3>
				<span>Tytuł: </span>
				{title}
			</h3>
			<div className="interior">
				<img src={photo} alt="" />
				<div>
					<p>Dodano: {date}</p>
					<Button input name="usuń" type="button" onClick={handleOpenDeleteModal} />
					<Button input name="edytuj" type="button" onClick={hendleOpenEditNewsModal} />
				</div>
			</div>
			<div className="element">
				<p>
					<span>Treść 1:</span>
				</p>
				{vievSplitedContent1}
			</div>
			<div className="element">
				<p>
					<span>Treść 2: </span>
				</p>
				{vievSplitedContent2}
			</div>
			<div className="signature">
				<p>
					<span>Podpis:</span>
				</p>
				{signature}
			</div>

			<DeleteConfirmation
				isDeleteModalOpen={isDeleteModalOpen}
				setIsDeleteModalOpen={setIsDeleteModalOpen}
				itemId={_id}
			/>
			<AddNewsItem
				isAddNewsModalOpen={isEditNewsItemModalOpen}
				setIsAddNewsModalOpen={setIsEditNewsItemModalOpen}
				isEdited={isEdited}
				setIsEdited={setIsEdited}
			/>
		</StyledWrapper>
	);
};

export default React.memo(NewsItem);
