import React from "react";
import { useSelector } from "react-redux";
import { LoginViev, MainViev } from "../../templates";

const StartViev = () => {
	const session = useSelector((store) => store.session);

	const selectedViev = !session ? <LoginViev /> : <MainViev />;

	return selectedViev;
};

export default React.memo(StartViev);
