import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllImportantMattersItems } from "../../data/actions";
import { AddItem, Button, Item } from "../../components";
import styled from "styled-components";

const StyledWrapper = styled.div`
	width: 90%;
	margin: 50px auto 20px auto;
	h2 {
		margin: 20px auto;
		color: ${(props) => props.theme.colors.box};
	}
	.items {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		margin-top: 30px;
	}
	.buttons {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
`;

const ImportantMattersViev = () => {
	const importantMatters = useSelector((store) => store.importantMatters);

	const dispatch = useDispatch();

	const [isAddItemModalOpen, setIsAddItemModalOpen] = useState(false);

	const handleOpenAddItemModal = () => {
		setIsAddItemModalOpen(true);
	};

	const mainInfoItems = !importantMatters.length ? (
		<p>Nie ma elementów do wyświetlenia w tej sekcji.</p>
	) : (
		importantMatters.reverse().map((item) => {
			return <Item key={item._id} id={item._id} content={item.content} date={item.date} title={item.title} />;
		})
	);

	const addItemButtonViev = !importantMatters.length ? (
		<Button name="dodaj" type="button" onClick={handleOpenAddItemModal} />
	) : (
		""
	);

	useEffect(() => {
		dispatch(getAllImportantMattersItems());
	}, [dispatch]);

	const navigate = useNavigate();

	const handleGoToStart = () => {
		navigate("/");
	};

	return (
		<StyledWrapper>
			<h2>Ważna informacja na stronie głównej</h2>
			<div className="buttons">
				<Button name="wstecz" type="button" onClick={handleGoToStart} />
				{addItemButtonViev}
			</div>
			<div className="items">{mainInfoItems}</div>
			<AddItem isAddItemModalOpen={isAddItemModalOpen} setIsAddItemModalOpen={setIsAddItemModalOpen} />
		</StyledWrapper>
	);
};

export default React.memo(ImportantMattersViev);
