import { GET_ALL_MAIN_INFO_ITEMS, ADD_MAIN_INFO_ITEM, EDIT_MAIN_INFO_ITEM, DELETE_MAIN_INFO_ITEM } from "../actions";

const initialState = [];

export const mainInfoReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ALL_MAIN_INFO_ITEMS:
			return action.payload;
		case ADD_MAIN_INFO_ITEM:
			return [...state, action.payload];
		case EDIT_MAIN_INFO_ITEM:
			const index = state.findIndex((item) => item._id === action.payload._id);
			state.splice(index, 1, action.payload);
			return [...state];
		case DELETE_MAIN_INFO_ITEM:
			return state.filter((item) => item._id !== action.payload);
		default:
			return state;
	}
};
