import request from "../../helpers/request";
import { addSpinner, removeSpinner, timeoutShowTask } from "./index";

export const GET_ALL_SECTION_INFO_ITEMS = "GET_ALL_SECTION_INFO_ITEMS";
export const ADD_SECTION_INFO_ITEM = "ADD_SECTION_INFO_ITEM";
export const EDIT_SECTION_INFO_ITEM = "EDIT_SECTION_INFO_ITEM";
export const DELETE_SECTION_INFO_ITEM = "DELETE_SECTION_INFO_ITEM";

export const getAllSectionInfoItems = () => async (dispatch) => {
	dispatch(addSpinner());
	const { data, status } = await request.get("info-section", {
		headers: { "Content-Type": "application/json" },
		credentials: "include",
		withCredentials: true,
	});

	if (status === 200) {
		dispatch(removeSpinner());
		dispatch({
			type: GET_ALL_SECTION_INFO_ITEMS,
			payload: data.data,
		});
	} else if (status === 404) {
		dispatch(removeSpinner());
		dispatch(timeoutShowTask("Nie ma żadnych elemetów do wyświetlenia"));
	} else {
		dispatch(removeSpinner());
		dispatch(timeoutShowTask(data.message || data.error));
	}
};

export const addSectionInfoItem = (sectionInfoItemData) => async (dispatch) => {
	dispatch(addSpinner());
	const { data, status } = await request.post("info-section", sectionInfoItemData, {
		headers: { "Content-Type": "application/json" },
		credentials: "include",
		withCredentials: true,
	});

	if (status === 201) {
		dispatch(removeSpinner());
		dispatch({
			type: ADD_SECTION_INFO_ITEM,
			payload: data.data,
		});
	} else {
		dispatch(removeSpinner());
		dispatch(timeoutShowTask(data.message || data.error));
	}
};

export const editSectionInfoItem = (sectionInfoItemData) => async (dispatch) => {
	dispatch(addSpinner());
	const { data, status } = await request.put(`info-section/${sectionInfoItemData.id}`, sectionInfoItemData, {
		headers: { "Content-Type": "application/json" },
		credentials: "include",
		withCredentials: true,
	});

	if (status === 200) {
		dispatch(removeSpinner());
		dispatch({
			type: EDIT_SECTION_INFO_ITEM,
			payload: data.data[0],
		});
	} else {
		dispatch(removeSpinner());
		dispatch(timeoutShowTask(data.message || data.error));
	}
};

export const deleteSectionInfoItem = (id) => async (dispatch) => {
	dispatch(addSpinner());
	const { data, status } = await request.delete(`info-section/${id}`, {
		headers: { "Content-Type": "application/json" },
		credentials: "include",
		withCredentials: true,
	});

	if (status === 200) {
		dispatch(removeSpinner());
		dispatch({
			type: DELETE_SECTION_INFO_ITEM,
			payload: id,
		});
	} else {
		dispatch(removeSpinner());
		dispatch(timeoutShowTask(data.message || data.error));
	}
};
