import { HashRouter as Router } from "react-router-dom";
import { Fragment } from "react";

import { MainSwitch, TaskInformation } from "./components";
import { Header } from "./templates";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import Spinner from "./components/Spinner/Spinner";

const GlobalStyle = createGlobalStyle`
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}
  body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    margin: 0 auto;
	 font-family: "Lato", sans-serif;
  }
`;

const theme = {
	radius: "10px",
	transition: "0.4s",
	colors: {
		first: "rgb(99, 42, 205)",
		second: "rgb(181, 178, 178)",
		third: "rgb(22,88, 999)",
		done: "rgb(2, 152, 2)",
		worrning: "rgb(242, 29, 29)",
		box: "rgb(111, 111, 111)",
		mainText: "rgb(255,255,333)",
	},
	fonts: {
		xs: "13px",
		s: "15px",
		m: "18px",
		l: "21px",
		x: "23px",
		xl: "28px",
	},
	breakpoints: {
		small: "480px",
		medium: "640px",
		large: "800px",
		xLarge: "1020px",
		big: "1280px",
	},
};

const App = () => {
	return (
		<Router>
			<ThemeProvider theme={theme}>
				<GlobalStyle />
				<Header />
				<Fragment>
					<MainSwitch />
				</Fragment>
				<Spinner />
				<TaskInformation />
			</ThemeProvider>
		</Router>
	);
};

export default App;
