import {
	GET_ALL_IMPORTANT_MATTERS_ITEMS,
	ADD_IMPORTANT_MATTERS_ITEM,
	EDIT_IMPORTANT_MATTERS_ITEM,
	DELETE_IMPORTANT_MATTERS_ITEM,
} from "../actions";

const initialState = [];

export const importantMattersReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ALL_IMPORTANT_MATTERS_ITEMS:
			return action.payload;
		case ADD_IMPORTANT_MATTERS_ITEM:
			return [...state, action.payload];
		case EDIT_IMPORTANT_MATTERS_ITEM:
			const index = state.findIndex((item) => item._id === action.payload._id);
			state.splice(index, 1, action.payload);
			return [...state];
		case DELETE_IMPORTANT_MATTERS_ITEM:
			return state.filter((item) => item._id !== action.payload);
		default:
			return state;
	}
};
