import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedViev } from "../../data/actions";
import { Button } from "../../components";
import styled from "styled-components";

const StyledWrapper = styled.div`
	width: 100%;
	.inside {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 90%;
		margin: 100px auto 0 auto;
		button {
			margin-bottom: 40px;
		}
	}
`;

const MainViev = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleGoToSection = (e) => {
		if (e.target.id === "news-viev") {
			navigate("/news-viev");
		} else if (e.target.id === "main-info") {
			dispatch(setSelectedViev(e.target.id));
			navigate("/info-viev");
		} else if (e.target.id === "section-info") {
			dispatch(setSelectedViev(e.target.id));
			navigate("/info-viev");
		} else if (e.target.id === "important-matters") {
			dispatch(setSelectedViev(e.target.id));
			navigate("/important-viev");
		}
	};

	return (
		<StyledWrapper>
			<div className="inside">
				<Button name="aktualności" id="news-viev" onClick={handleGoToSection} />
				<Button name="ogłoszenia" id="main-info" onClick={handleGoToSection} />
				{/* <Button name="sukcesy" id="section-info" onClick={handleGoToSection} /> */}
				<Button name="ważna informacja" id="important-matters" onClick={handleGoToSection} />
			</div>
		</StyledWrapper>
	);
};

export default React.memo(MainViev);
