import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ImportantMattersViev, MainInfoViev, NewsViev, StartViev } from "../../templates";

const MainSwitch = () => {
	const session = useSelector((store) => store.session);
	const user = useSelector((store) => store.user);

	return (
		<>
			<Routes>
				<Route exact path="/" element={<StartViev />} />
				{!session && !user.length ? (
					<Route exact path="/" element={<StartViev />} />
				) : (
					<Route exact path="/news-viev" element={<NewsViev />} />
				)}
				{!session && !user.length ? (
					<Route exact path="/" element={<StartViev />} />
				) : (
					<Route exact path="/info-viev" element={<MainInfoViev />} />
				)}
				{!session && !user.length ? (
					<Route exact path="/" element={<StartViev />} />
				) : (
					<Route exact path="/important-viev" element={<ImportantMattersViev />} />
				)}
				<Route path="*" element={<Navigate to="/" />} />
			</Routes>
		</>
	);
};
export default MainSwitch;
