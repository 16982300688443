export const addSessionItem = (id) => {
	window.sessionStorage.setItem("userForKinderGarten", id);
};

export const deleteSessionItem = () => {
	window.sessionStorage.removeItem("userForKinderGarten");
};

export const checkSessionItem = () => {
	return window.sessionStorage.getItem("userForKinderGarten");
};
