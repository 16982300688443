import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const StyledSpinner = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 100;

	@keyframes ldio-2r6k37hlfxt-1 {
		0% {
			top: 36px;
			height: 64px;
		}
		50% {
			top: 60px;
			height: 40px;
		}
		100% {
			top: 60px;
			height: 40px;
		}
	}
	@keyframes ldio-2r6k37hlfxt-2 {
		0% {
			top: 41.99999999999999px;
			height: 58.00000000000001px;
		}
		50% {
			top: 60px;
			height: 40px;
		}
		100% {
			top: 60px;
			height: 40px;
		}
	}
	@keyframes ldio-2r6k37hlfxt-3 {
		0% {
			top: 48px;
			height: 52px;
		}
		50% {
			top: 60px;
			height: 40px;
		}
		100% {
			top: 60px;
			height: 40px;
		}
	}
	.ldio-2r6k37hlfxt div {
		position: absolute;
		width: 20px;
	}
	.ldio-2r6k37hlfxt div:nth-child(1) {
		left: 35px;
		background: ${(props) => props.theme.colors.box};
		animation: ldio-2r6k37hlfxt-1 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
		animation-delay: -0.2s;
	}
	.ldio-2r6k37hlfxt div:nth-child(2) {
		left: 85px;
		background: ${(props) => props.theme.colors.third};
		animation: ldio-2r6k37hlfxt-2 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
		animation-delay: -0.1s;
	}
	.ldio-2r6k37hlfxt div:nth-child(3) {
		left: 135px;
		background: ${(props) => props.theme.colors.done};
		animation: ldio-2r6k37hlfxt-3 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
		animation-delay: undefineds;
	}

	.loadingio-spinner-pulse-m74nu2u9pl {
		width: 200px;
		height: 150px;
		display: inline-block;
		overflow: hidden;
		background: transparent;
		opacity: 0.85;
	}
	.ldio-2r6k37hlfxt {
		width: 100%;
		height: 100%;
		position: relative;
		transform: translateZ(0) scale(1);
		backface-visibility: hidden;
		transform-origin: 0 0; /* see note above */
	}
	.ldio-2r6k37hlfxt div {
		box-sizing: content-box;
	}
`;

const Spinner = () => {
	const spinner = useSelector((store) => store.spinner);

	// const spinner = true;

	const spinnerViev = spinner ? (
		<StyledSpinner>
			<div className="loadingio-spinner-pulse-m74nu2u9pl">
				<div className="ldio-2r6k37hlfxt">
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		</StyledSpinner>
	) : (
		""
	);
	return <>{spinnerViev}</>;
};
export default React.memo(Spinner);
