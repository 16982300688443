import styled from "styled-components";

const StyledInput = styled.input`
	width: 100%;
	padding: 5px;
	border-radius: 5px;
	color: ${(props) => props.theme.colors.third};
	font-weight: 700;
	@media screen and (min-width: ${(props) => props.theme.breakpoints.xLarge}) {
		font-size: ${(props) => props.theme.fonts.m};
	}
`;

const Input = ({ type, name, onChange, onBlur, value, placeholder }) => {
	return (
		<StyledInput
			type={type}
			name={name}
			onChange={onChange}
			onBlur={onBlur}
			value={value}
			placeholder={placeholder}
		/>
	);
};

export default Input;
