import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
	addMainInfoItem,
	addSectionInfoItem,
	addImportantMattersItem,
	editMainInfoItem,
	editSectionInfoItem,
	editImportantMattersItem,
} from "../../data/actions";
import { Button, Input, Modal, Textarea } from "../../components";
import styled from "styled-components";

const StyledWrapper = styled.div`
	width: 300px;
	background-color: ${(props) => props.theme.colors.second};
	border: 2px solid ${(props) => props.theme.colors.box};
	border-radius: ${(props) => props.theme.radius};
	padding: 20px;
	p {
		color: ${(props) => props.theme.colors.mainText};
		font-weight: 700;
		text-align: center;
		margin-bottom: 10px;
	}
	form {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		width: 100%;
		P {
			font-size: ${(props) => props.theme.fonts.xs};
			font-weight: 500;
			color: ${(props) => props.theme.colors.worrning};
		}
		.buttons {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			margin-top: 20px;
		}
	}
	@media screen and (orientation: landscape) {
		width: 600px;
		form {
			flex-direction: row;
			flex-wrap: wrap;
			input {
				flex-basis: 45%;
			}

			textarea {
				flex-basis: 45%;
				height: 120px;
				margin-top: 20px;
			}
		}
	}
	@media screen and (min-width: ${(props) => props.theme.breakpoints.xLarge}) {
		width: 600px;
		p {
			font-size: ${(props) => props.theme.fonts.l};
		}
		form {
			flex-direction: column;
			input {
				margin-top: 20px;
			}

			textarea {
				height: 300px;
				margin-top: 20px;
			}
			p {
				font-size: ${(props) => props.theme.fonts.m};
			}
		}
	}
`;

const SignupSchema = Yup.object().shape({
	title: Yup.string().required("Pole wymagane"),
	content: Yup.string().required("Pole wymagane"),
	date: Yup.date().required("Pole wymagane"),
	signature: Yup.string().required("Pole wymagane"),
});

const AddItem = ({ isAddItemModalOpen, setIsAddItemModalOpen, isEdited = false, setIsEdited = false }) => {
	const switchViev = useSelector((store) => store.switchViev);
	const dispatch = useDispatch();

	const initialValues = {
		title: isEdited ? isEdited.title : "",
		content: isEdited ? isEdited.content : "",
		content1: isEdited ? isEdited.content1 : "",
		date: isEdited ? isEdited.date : "",
		signature: isEdited ? isEdited.signature : "",
	};

	const handleOnSubmit = (values, { setSubmitting }) => {
		if (isEdited) {
			values.id = isEdited.id;
			if (switchViev === "main-info") {
				dispatch(editMainInfoItem(values));
			} else if (switchViev === "section-info") {
				dispatch(editSectionInfoItem(values));
			} else if (switchViev === "important-matters") {
				dispatch(editImportantMattersItem(values));
			}
		} else {
			if (switchViev === "main-info") {
				dispatch(addMainInfoItem(values));
			} else if (switchViev === "section-info") {
				dispatch(addSectionInfoItem(values));
			} else if (switchViev === "important-matters") {
				dispatch(addImportantMattersItem(values));
			}
		}
		setIsAddItemModalOpen(false);
		if (isEdited) {
			setIsEdited(false);
		}
	};

	const handleCloseAddItemModal = () => {
		setIsAddItemModalOpen(false);
		if (isEdited) {
			setIsEdited(false);
		}
	};

	return (
		<Modal isModalOpen={isAddItemModalOpen}>
			<StyledWrapper>
				<p>{isEdited ? "Edycja elementu" : "Dodawanie elementu"}</p>
				<Formik
					enableReinitialize
					initialValues={initialValues}
					validationSchema={SignupSchema}
					onSubmit={handleOnSubmit}
				>
					{({
						values,
						// initialValues,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
						resetForm,
					}) => (
						<form onSubmit={handleSubmit}>
							<Input
								type="date"
								name="date"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.date}
							/>
							<p> {errors.date && touched.date && errors.date}</p>
							<Input
								type="text"
								name="title"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.title}
								placeholder="Tytuł"
							/>
							<p> {errors.title && touched.title && errors.title}</p>
							<Textarea
								type="text"
								name="content"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.content}
								placeholder="Tekst"
								cols="30"
								rows="10"
							/>
							<p> {errors.content && touched.content && errors.content}</p>
							<Input
								type="text"
								name="signature"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.signature}
								placeholder="Podpis"
							/>
							<p> {errors.signature && touched.signature && errors.signature}</p>
							{switchViev === "section-info" ? (
								<Textarea
									type="text"
									name="content1"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.content1}
									placeholder="Tekst"
									cols="30"
									rows="10"
								/>
							) : (
								""
							)}
							<div className="buttons">
								<Button input type="button" name="wyjdź" onClick={handleCloseAddItemModal} />
								{isEdited ? "" : <Button input name="wyczyść" onClick={resetForm} />}
								<Button input type="submit" name="zapisz" />
							</div>
						</form>
					)}
				</Formik>
			</StyledWrapper>
		</Modal>
	);
};

export default React.memo(AddItem);
