import { combineReducers } from "redux";
import { importantMattersReducer } from "./importantMattersReducer";
import { mainInfoReducer } from "./mainInfoReducer";
import { newsReducer } from "./newsReducer";
import { sectionInfoReducer } from "./sectionInfoReducer";
import { sessionStorageReducer } from "./sessionStorageReducer";
import { spinnerReducer } from "./spinnerReducer";
import { switchVievReducer } from "./switchVievReducer";
import { taskReducer } from "./taskReducer";
import { userReducer } from "./userReducer";

export const rootReducer = combineReducers({
	importantMatters: importantMattersReducer,
	mainInfo: mainInfoReducer,
	news: newsReducer,
	sectionInfo: sectionInfoReducer,
	session: sessionStorageReducer,
	spinner: spinnerReducer,
	switchViev: switchVievReducer,
	task: taskReducer,
	user: userReducer,
});
