import styled from "styled-components";

const StyledTextarea = styled.textarea`
	width: 100%;
	padding: 5px;
	border-radius: 5px;
	color: ${(props) => props.theme.colors.third};

	@media screen and (min-width: ${(props) => props.theme.breakpoints.xLarge}) {
		font-size: ${(props) => props.theme.fonts.m};
	}
`;

const Textarea = ({ type, name, onChange, onBlur, value, placeholder, cols, rows }) => {
	return (
		<StyledTextarea
			type={type}
			name={name}
			onChange={onChange}
			onBlur={onBlur}
			value={value}
			placeholder={placeholder}
			cols={cols}
			rows={rows}
		/>
	);
};

export default Textarea;
