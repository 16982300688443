import {
	GET_ALL_SECTION_INFO_ITEMS,
	ADD_SECTION_INFO_ITEM,
	EDIT_SECTION_INFO_ITEM,
	DELETE_SECTION_INFO_ITEM,
} from "../actions";

const initialState = [];

export const sectionInfoReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ALL_SECTION_INFO_ITEMS:
			return action.payload;
		case ADD_SECTION_INFO_ITEM:
			return [...state, action.payload];
		case EDIT_SECTION_INFO_ITEM:
			const index = state.findIndex((item) => item._id === action.payload._id);
			state.splice(index, 1, action.payload);
			return [...state];
		case DELETE_SECTION_INFO_ITEM:
			return state.filter((item) => item._id !== action.payload);
		default:
			return state;
	}
};
