import { useDispatch, useSelector } from "react-redux/es/exports";
import {
	deleteMainInfoItem,
	deleteSectionInfoItem,
	deleteImportantMattersItem,
	deleteNewsItem,
	setSelectedViev,
} from "../../data/actions";
import { Button, Modal } from "../../components";
import styled from "styled-components";

const StyledWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	width: 260px;
	height: 180px;
	border-radius: ${(props) => [props.theme.radius]};
	background-color: ${(props) => [props.theme.colors.third]};
	padding: 20px;
	h3 {
		flex-basis: 100%;
		color: ${(props) => [props.theme.colors.mainText]};
		text-align: center;
	}
`;

const DeleteConfirmation = ({ isDeleteModalOpen, setIsDeleteModalOpen, itemId }) => {
	const switchViev = useSelector((store) => store.switchViev);
	const dispatch = useDispatch();

	const handleCloseDeleteModal = () => {
		setIsDeleteModalOpen(false);
		dispatch(setSelectedViev(null));
	};

	const handleDeleteItem = () => {
		if (switchViev === "main-info") {
			dispatch(deleteMainInfoItem(itemId));
		} else if (switchViev === "section-info") {
			dispatch(deleteSectionInfoItem(itemId));
		} else if (switchViev === "important-matters") {
			dispatch(deleteImportantMattersItem(itemId));
		} else if (switchViev === "news") {
			dispatch(deleteNewsItem(itemId));
		}
		setIsDeleteModalOpen(false);
	};

	return (
		<Modal isModalOpen={isDeleteModalOpen}>
			<StyledWrapper>
				<h3>Potwierdź usunięcie elementu</h3>
				<Button input name="wyjdź" onClick={handleCloseDeleteModal} />
				<Button input name="usuń" onClick={handleDeleteItem} />
			</StyledWrapper>
		</Modal>
	);
};

export default DeleteConfirmation;
