import { useState } from "react";
import { useSelector } from "react-redux";
import { AddItem, Button, DeleteConfirmation } from "../../components";
import styled from "styled-components";

const StyledWrapper = styled.div`
	width: 85%;
	margin-bottom: 10px;
	border: 2px solid ${(props) => props.theme.colors.box};
	border-radius: ${(props) => props.theme.radius};
	padding: 10px;
	.inside {
		display: flex;
		flex-direction: column;
		width: 100%;
		h3,
		p {
			text-align: left;
			margin-bottom: 10px;
			font-size: ${(props) => props.theme.fonts.xs};
		}
		.date {
			border-bottom: 1px solid ${(props) => props.theme.colors.box};
		}
		.signature {
			border-bottom: 1px solid ${(props) => props.theme.colors.box};
			text-align: right;
		}

		.buttons {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
	@media screen and (min-width: ${(props) => props.theme.breakpoints.large}) {
		.inside {
			h3,
			p {
				font-size: ${(props) => props.theme.fonts.s};
			}
		}
	}
`;

const Item = ({ id, content, content1, date, title, signature }) => {
	const switchViev = useSelector((store) => store.switchViev);

	const [isAddItemModalOpen, setIsAddItemModalOpen] = useState(false);
	const [isEdited, setIsEdited] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	const switchedSelectedViev = switchViev === "section-info" ? <p>Tekst2: {content1}</p> : "";

	const handleOpenDeleteConfirmationModal = () => {
		setIsDeleteModalOpen(true);
	};

	const handleEditMainInfoItem = () => {
		setIsEdited({ id, content, content1, date, title, signature });
		setIsAddItemModalOpen(true);
	};

	const splitedContent = content.split(/\n/);
	const vievSplitedContent = splitedContent.map((item, index) => <p key={index}>{item}</p>);

	return (
		<StyledWrapper>
			<div className="inside">
				<h3>Tytuł: {title}</h3>
				<p className="date">Data dodania: {date}</p>
				{vievSplitedContent}
				<p className="signature">{signature}</p>
				{switchedSelectedViev}

				<div className="buttons">
					<Button input name="edytuj" type="button" onClick={handleEditMainInfoItem} />
					<Button input name="usuń" type="button" onClick={handleOpenDeleteConfirmationModal} />
				</div>
			</div>
			<AddItem
				isAddItemModalOpen={isAddItemModalOpen}
				setIsAddItemModalOpen={setIsAddItemModalOpen}
				isEdited={isEdited}
				setIsEdited={setIsEdited}
			/>
			<DeleteConfirmation
				isDeleteModalOpen={isDeleteModalOpen}
				setIsDeleteModalOpen={setIsDeleteModalOpen}
				itemId={id}
			/>
		</StyledWrapper>
	);
};

export default Item;
