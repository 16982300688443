import request from "../../helpers/request";
import { addSpinner, removeSpinner, timeoutShowTask } from "./index";

export const GET_ALL_MAIN_INFO_ITEMS = "GET_ALL_MAIN_INFO_ITEMS";
export const ADD_MAIN_INFO_ITEM = "ADD_MAIN_INFO_ITEM";
export const EDIT_MAIN_INFO_ITEM = "EDIT_MAIN_INFO_ITEM";
export const DELETE_MAIN_INFO_ITEM = "DELETE_MAIN_INFO_ITEM";

export const getAllMainInfoItems = () => async (dispatch) => {
	dispatch(addSpinner());
	const { data, status } = await request.get("info-main", {
		headers: { "Content-Type": "application/json" },
		credentials: "include",
		withCredentials: true,
	});

	if (status === 200) {
		dispatch(removeSpinner());
		dispatch({
			type: GET_ALL_MAIN_INFO_ITEMS,
			payload: data.data,
		});
	} else if (status === 404) {
		dispatch(removeSpinner());
		dispatch(timeoutShowTask("Nie ma żadnych elemetów do wyświetlenia"));
	} else {
		dispatch(removeSpinner());
		dispatch(timeoutShowTask(data.message || data.error));
	}
};

export const addMainInfoItem = (mainInfoItemData) => async (dispatch) => {
	dispatch(addSpinner());
	const { data, status } = await request.post("info-main", mainInfoItemData, {
		headers: { "Content-Type": "application/json" },
		credentials: "include",
		withCredentials: true,
	});

	if (status === 201) {
		dispatch(removeSpinner());
		dispatch({
			type: ADD_MAIN_INFO_ITEM,
			payload: data.data,
		});
	} else {
		dispatch(removeSpinner());
		dispatch(timeoutShowTask(data.message || data.error));
	}
};

export const editMainInfoItem = (mainInfoItemData) => async (dispatch) => {
	dispatch(addSpinner());
	const { data, status } = await request.put(`info-main/${mainInfoItemData.id}`, mainInfoItemData, {
		headers: { "Content-Type": "application/json" },
		credentials: "include",
		withCredentials: true,
	});

	if (status === 200) {
		dispatch(removeSpinner());
		dispatch({
			type: EDIT_MAIN_INFO_ITEM,
			payload: data.data[0],
		});
	} else {
		dispatch(removeSpinner());
		dispatch(timeoutShowTask(data.message || data.error));
	}
};

export const deleteMainInfoItem = (id) => async (dispatch) => {
	dispatch(addSpinner());
	const { data, status } = await request.delete(`info-main/${id}`, {
		headers: { "Content-Type": "application/json" },
		credentials: "include",
		withCredentials: true,
	});

	if (status === 200) {
		dispatch(removeSpinner());
		dispatch({
			type: DELETE_MAIN_INFO_ITEM,
			payload: id,
		});
	} else {
		dispatch(removeSpinner());
		dispatch(timeoutShowTask(data.message || data.error));
	}
};
