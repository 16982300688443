import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllNews } from "../../data/actions";
import { AddNewsItem, Button, NewsItem } from "../../components";
import styled from "styled-components";

const StyledWrapper = styled.div`
	width: 90%;
	margin: 0 auto;
	h2 {
		text-align: center;
		color: ${(props) => props.theme.colors.box};
		margin: 20px;
	}
	.items {
		p {
			text-align: center;
		}
	}
	.buttons {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin: 30px auto;
	}
`;

const NewsViev = () => {
	const news = useSelector((store) => store.news);
	const dispatch = useDispatch();

	const [isAddNewsModalOpen, setIsAddNewsModalOpen] = useState(false);

	useEffect(() => {
		if (!news.length) {
			dispatch(getAllNews());
		} else {
			return;
		}
	}, [news, dispatch]);

	const navigate = useNavigate();

	const handleGoToStart = () => {
		navigate("/");
	};

	const handleOpenAddNewsModal = () => {
		setIsAddNewsModalOpen(true);
	};

	const newsItems = !news.length ? (
		<p>Nie ma elementów do wyświetlenia w tej sekcji.</p>
	) : (
		news
			.map((item) => {
				return <NewsItem key={item._id} item={item} />;
			})
			.reverse()
	);

	return (
		<StyledWrapper>
			<h2>Aktualności</h2>
			<div className="buttons">
				<Button name="wstecz" type="button" onClick={handleGoToStart} />
				<Button name="dodaj" type="button" onClick={handleOpenAddNewsModal} />
			</div>
			<div className="items">{newsItems}</div>
			<AddNewsItem isAddNewsModalOpen={isAddNewsModalOpen} setIsAddNewsModalOpen={setIsAddNewsModalOpen} />
		</StyledWrapper>
	);
};

export default React.memo(NewsViev);
