import { SET_SELECTED_VEIV, RESET_VIEVS } from "../actions";

const initialState = null;

export const switchVievReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_SELECTED_VEIV:
			return (state = action.payload);
		case RESET_VIEVS:
			return initialState;
		default:
			return state;
	}
};
