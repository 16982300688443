import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../components";
import { addLogout, sessionItemCheck } from "../../data/actions";
import styled from "styled-components";

const StyledWrapper = styled.div`
	width: 100%;
	margin: 0 auto;
	background-color: ${(props) => props.theme.colors.third};
`;
const StyledInside = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 90%;
	margin: 0 auto;
	padding: 20px;
	h3 {
		font-size: ${(props) => props.theme.fonts.m};
		font-weight: 700;
		text-align: center;
		color: white;
	}
	@media screen and (min-width: ${(props) => props.theme.breakpoints.medium}) {
		h3 {
			font-size: ${(props) => props.theme.fonts.l};
		}
	}
	@media screen and (min-width: ${(props) => props.theme.breakpoints.xLarge}) {
		h3 {
			font-size: ${(props) => props.theme.fonts.xl};
		}
	}
`;

const Header = () => {
	const session = useSelector((store) => store.session);
	const user = useSelector((store) => store.user);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(sessionItemCheck());
	}, [dispatch]);

	const handleLogoutUser = () => {
		dispatch(addLogout());
	};

	return (
		<StyledWrapper>
			<StyledInside>
				<h3>Administracja strony przedszkola</h3>
				{session || user.length ? <Button light name="wyloguj" type="button" onClick={handleLogoutUser} /> : ""}
			</StyledInside>
		</StyledWrapper>
	);
};

export default React.memo(Header);
