import React from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { userLogin } from "../../data/actions";
import { Button } from "../../components";
import styled from "styled-components";

const StyledWrapper = styled.div`
	width: "100%";
	margin: "0 auto";

	.inside {
		position: relative;
		width: 90%;
		margin: 0 auto;
		margin-top: 200px;
		.loginBox {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 300px;
			height: 300px;
			border: 2px solid ${(props) => props.theme.colors.third};
			border-radius: ${(props) => props.theme.radius};
			p {
				color: ${(props) => props.theme.colors.third};
				font-weight: 700;
				text-align: center;
				margin: 20px;
			}
			form {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				width: 80%;
				margin: 20px auto;
				input {
					padding: 5px;
					border: 1px solid ${(props) => props.theme.colors.third};
					border-radius: 5px;
				}
				p {
					margin-top: 0px;
					margin-bottom: 20px;
					font-weight: 500;
					color: ${(props) => props.theme.colors.worrning};
				}
			}
		}
	}
	@media screen and (orientation: landscape) {
		.inside {
			margin-top: 140px;
			.loginBox {
				height: 250px;
			}
		}
	}
	@media screen and (min-width: ${(props) => props.theme.breakpoints.xLarge}) {
		.inside {
			margin-top: 200px;
			.loginBox {
				height: 300px;
			}
		}
	}
`;

const LoginViev = () => {
	const dispatch = useDispatch();

	return (
		<StyledWrapper>
			<div className="inside">
				<div className="loginBox">
					<p>Logowanie</p>
					<Formik
						initialValues={{ email: "", password: "" }}
						validate={(values) => {
							const errors = {};
							if (!values.email) {
								errors.email = "Pole wymagane";
							} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
								errors.email = "Niepoprawny adres eMail";
							}
							return errors;
						}}
						onSubmit={(values, { setSubmitting }) => {
							dispatch(userLogin(values));
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
							/* and other goodies */
						}) => (
							<form onSubmit={handleSubmit}>
								<input
									type="email"
									name="email"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.email}
									placeholder="eMail"
								/>
								<p> {errors.email && touched.email && errors.email}</p>
								<input
									type="password"
									name="password"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.password}
									placeholder="hasło"
								/>
								<p> {errors.email && touched.email && errors.email}</p>

								<Button type="submit" name="zaloguj" />
							</form>
						)}
					</Formik>
				</div>
			</div>
		</StyledWrapper>
	);
};

export default React.memo(LoginViev);
