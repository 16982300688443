import React from "react";
import { useDispatch } from "react-redux";
import { addNewsItem, editNewsItem } from "../../data/actions";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Input, Textarea, Modal } from "..";

import styled from "styled-components";

const StyledWrapper = styled.div`
	width: 95vw;
	background-color: ${(props) => props.theme.colors.second};
	border: 2px solid ${(props) => props.theme.colors.box};
	border-radius: ${(props) => props.theme.radius};
	padding: 10px;
	p {
		color: ${(props) => props.theme.colors.mainText};
		font-weight: 700;
		text-align: center;
		margin-bottom: 10px;
	}
	form {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		width: 100%;
		.element {
			width: 100%;

			P {
				font-size: ${(props) => props.theme.fonts.xs};
				font-weight: 500;
				color: ${(props) => props.theme.colors.worrning};
			}
			span {
				font-weight: 700;
				text-align: center;
				font-size: ${(props) => props.theme.fonts.xs};
			}
		}

		.buttons {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			margin-top: 20px;
		}
	}
	@media screen and (orientation: landscape) {
		form {
			flex-direction: row;
			flex-wrap: wrap;
			.element {
				flex-basis: 45%;
				textarea {
					height: 100px;
				}
			}
			.element:nth-child(3) {
				flex-basis: 100%;
				margin-bottom: 10px;
			}
		}
	}
	@media screen and (min-width: ${(props) => props.theme.breakpoints.xLarge}) {
		width: 600px;
		p {
			font-size: ${(props) => props.theme.fonts.l};
		}
		form {
			flex-direction: column;
			input {
				margin-top: 20px;
			}

			textarea {
				height: 300px;
				margin-top: 20px;
			}
			p {
				font-size: ${(props) => props.theme.fonts.m};
			}
		}
	}
`;

const SignupSchema = Yup.object().shape({
	title: Yup.string().required("Pole wymagane"),
	content1: Yup.string().required("Pole wymagane"),
	date: Yup.date().required("Pole wymagane"),
	signature: Yup.string().required("Pole wymagane"),
});

const AddNewsItem = ({ isAddNewsModalOpen, setIsAddNewsModalOpen, isEdited = false, setIsEdited = false }) => {
	const dispatch = useDispatch();

	const initialValues = {
		title: isEdited ? isEdited.title : "",
		content1: isEdited ? isEdited.content1 : "",
		content2: isEdited ? isEdited.content2 : "",
		photo: null,
		date: isEdited ? isEdited.date : "",
		signature: isEdited ? isEdited.signature : "",
	};

	const handleOnSubmit = (values, { setSubmitting }) => {
		if (isEdited) {
			values.id = isEdited._id;
			dispatch(editNewsItem(values));
		} else {
			dispatch(addNewsItem(values));
		}
		setIsAddNewsModalOpen(false);
	};

	const handleCloseAddItemModal = () => {
		if (isEdited) {
			setIsEdited(false);
		}
		setIsAddNewsModalOpen(false);
	};
	return (
		<Modal isModalOpen={isAddNewsModalOpen}>
			<StyledWrapper>
				<p>{isEdited ? "Edycja elementu" : "Dodawanie elementu"}</p>
				<Formik
					enableReinitialize
					initialValues={initialValues}
					validationSchema={SignupSchema}
					onSubmit={handleOnSubmit}
				>
					{({
						values,
						// initialValues,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
						resetForm,
						setFieldValue,
					}) => (
						<form onSubmit={handleSubmit} encType="multipart/form-data">
							<div className="element">
								<Input
									type="date"
									name="date"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.date}
								/>
								<p> {errors.date && touched.date && errors.date}</p>
							</div>
							<div className="element">
								<Input
									type="text"
									name="title"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.title}
									placeholder="Tytuł"
								/>
								<p> {errors.title && touched.title && errors.title}</p>
							</div>
							<div className="element">
								<Input
									type="file"
									name="photo"
									accept="image/*"
									onChange={(e) => setFieldValue("photo", e.currentTarget.files[0])}
								/>
								{!isEdited ? (
									""
								) : (
									<span>Jeżeli chcesz zmienić istniejące zdjęcie wybierz je teraz</span>
								)}
							</div>

							<div className="element">
								<Textarea
									type="text"
									name="content1"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.content1}
									placeholder="Tekst"
									cols="30"
									rows="10"
								/>
								<p> {errors.content1 && touched.content1 && errors.content1}</p>
							</div>
							<div className="element">
								<Textarea
									type="text"
									name="content2"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.content2}
									placeholder="Tekst"
									cols="30"
									rows="10"
								/>
							</div>
							<div className="element">
								<Input
									type="text"
									name="signature"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.signature}
									placeholder="Podpis"
								/>
								<p> {errors.signature && touched.signature && errors.signature}</p>
							</div>
							<div className="buttons">
								<Button input type="button" name="wyjdź" onClick={handleCloseAddItemModal} />
								{isEdited ? "" : <Button input type="button" name="wyczyść" onClick={resetForm} />}
								<Button input type="submit" name="zapisz" />
							</div>
						</form>
					)}
				</Formik>
			</StyledWrapper>
		</Modal>
	);
};

export default React.memo(AddNewsItem);
