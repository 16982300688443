import request from "../../helpers/request";
import { addSpinner, removeSpinner, timeoutShowTask } from "./index";

export const GET_ALL_NEWS = "GET_ALL_NEWS";
export const ADD_NEWS_ITEM = "ADD_NEWS_ITEM";
export const EDIT_NEWS_ITEM = "EDIT_NEWS_ITEM";
export const DETELE_NEWS_ITEM = "DETELE_NEWS_ITEM";

export const getAllNews = () => async (dispatch) => {
	dispatch(addSpinner());
	const { data, status } = await request.get("news", {
		headers: { "Content-Type": "application/json" },
		credentials: "include",
		withCredentials: true,
	});
	if (status === 200) {
		dispatch(removeSpinner());
		// data.forEach((item) => (item.photo = `https://kindergarten-back.herokuapp.com/news/photo/${item._id}`));
		// data.forEach((item) => (item.photo = `http://localhost:8080/news/photo/${item._id}`));
		data.forEach((item) => (item.photo = `https://www.tslapp.ovh/news/photo/${item._id}`));
		dispatch({
			type: GET_ALL_NEWS,
			payload: data,
		});
	} else if (status === 404) {
		dispatch(removeSpinner());
		dispatch(timeoutShowTask("Nie ma żadnych elemetów do wyświetlenia"));
	} else {
		dispatch(removeSpinner());
		dispatch(timeoutShowTask(data.message || data.error));
	}
};

export const addNewsItem = (newsItem) => async (dispatch) => {
	dispatch(addSpinner());

	const { data, status } = await request.post("news", newsItem, {
		headers: { "Content-Type": "multipart/form-data" },
		credentials: "include",
		withCredentials: true,
	});

	if (status === 201) {
		dispatch(removeSpinner());
		// data.photo = `https://kindergarten-back.herokuapp.com/news/photo/${data._id}`;
		// data.photo = `http://localhost:8080/news/photo/${data._id}`;
		data.photo = `https://www.tslapp.ovh/news/photo/${data._id}`;
		dispatch({
			type: ADD_NEWS_ITEM,
			payload: data,
		});
	} else {
		dispatch(removeSpinner());
		dispatch(timeoutShowTask("Przepraszamy błąd po stronie serwera, spróbuj jeszcze raz"));
	}
};

export const editNewsItem = (newsItem) => async (dispatch) => {
	dispatch(addSpinner());
	const { data, status } = await request.put(`news/${newsItem.id}`, newsItem, {
		headers: { "Content-Type": "multipart/form-data" },
		credentials: "include",
		withCredentials: true,
	});

	if (status === 200) {
		setTimeout(() => {
			dispatch(removeSpinner());
			// data.photo = `https://kindergarten-back.herokuapp.com/news/photo/${data._id}`;
			// data.photo = `http://localhost:8080/news/photo/${data._id}`;
			data.forEach((item) => (item.photo = `https://www.tslapp.ovh/news/photo/${item._id}`));
			dispatch({
				type: EDIT_NEWS_ITEM,
				payload: data,
			});
		}, [2500]);
	} else {
		dispatch(removeSpinner());
		dispatch(timeoutShowTask("Przepraszamy błąd po stronie serwera, spróbuj jeszcze raz"));
	}
};

export const deleteNewsItem = (newsId) => async (dispatch) => {
	dispatch(addSpinner());
	const { data, status } = await request.delete(`news/${newsId}`, {
		headers: { "Content-Type": "application/json" },
		credentials: "include",
		withCredentials: true,
	});

	if (status === 200) {
		dispatch(removeSpinner());
		dispatch({
			type: DETELE_NEWS_ITEM,
			payload: newsId,
		});
	} else {
		dispatch(removeSpinner());
		dispatch(timeoutShowTask("Przepraszamy błąd po stronie serwera, spróbuj jeszcze raz"));
	}
};
