import React, { useMemo } from "react";
import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

const StyledModal = styled.div`
	display: none;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 200;
	backdrop-filter: blur(2px);

	.wrapper {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 20px;
		z-index: 20;
		border: none;
		background-color: $firstColor;
		border-radius: $radius;
		border: 1px solid $thirdColor;

		@media screen and (orientation: landscape) {
			padding: 30px;
		}
	}
`;

function Modal({ children, isModalOpen, handleOnCloseModal }) {
	const options = useMemo(
		() => ({
			children,
			isModalOpen,
			handleOnCloseModal,
		}),
		[children, isModalOpen, handleOnCloseModal],
	);

	const modalRef = useRef(null);
	const previousActiveElement = useRef(null);

	useEffect(() => {
		if (!modalRef.current) {
			return;
		}

		const { current: modal } = modalRef;
		if (options.isModalOpen) {
			previousActiveElement.current = document.activeElement;
			modal.style.display = "block";
		} else if (previousActiveElement.current) {
			modal.style.display = "none";
			previousActiveElement.current.focus();
		}
	}, [options.isModalOpen]);

	useEffect(() => {
		const { current: modal } = modalRef;
		const handleCancel = (event) => {
			event.preventDefault();
			handleOnCloseModal();
		};
		modal.addEventListener("cancel", handleCancel);
		return () => {
			modal.removeEventListener("cancel", handleCancel);
		};
	}, [handleOnCloseModal]);

	// const handleOutsideClick = ({ target }) => {
	//   const { current } = modalRef;
	//   if (target === current) {
	//     handleOnCloseModal();
	//   }
	// };

	return createPortal(
		<StyledModal ref={modalRef}>
			<div className="wrapper">{options.children}</div>
		</StyledModal>,

		document.querySelector("#modal"),
	);
}

export default React.memo(Modal);
