import React from "react";
import { useSelector } from "react-redux";
import { Modal } from "../../components";
import styled from "styled-components";

const StyledWrapper = styled.div`
	width: 250px;
	height: 100px;
	border-radius: ${(props) => props.theme.radius};
	background-color: ${(props) => props.theme.colors.box};
	z-index: 100;
	.inside {
		position: relative;
		width: 100%;
		height: 100%;
		p {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
			padding: 5px;
			color: white;
			font-weight: 500;
			text-align: center;
		}
	}
`;

const TaskInformation = () => {
	const task = useSelector((store) => store.task);

	return (
		<Modal isModalOpen={task.isModalOpen}>
			<StyledWrapper>
				<div className="inside">
					<p>{task.task}</p>
				</div>
			</StyledWrapper>
		</Modal>
	);
};
export default React.memo(TaskInformation);
