import { ADD_NEWS_ITEM, DETELE_NEWS_ITEM, EDIT_NEWS_ITEM, GET_ALL_NEWS } from "../actions";

const initialState = [];

export const newsReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ALL_NEWS:
			return (state = action.payload);
		case ADD_NEWS_ITEM:
			return [...state, action.payload];
		case EDIT_NEWS_ITEM:
			const index = state.findIndex((item) => item._id === action.payload._id);
			state.splice(index, 1, action.payload);
			return [...state];
		case DETELE_NEWS_ITEM:
			return state.filter((item) => item._id !== action.payload);
		default:
			return state;
	}
};
