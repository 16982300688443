import styled from "styled-components";

const StyledButton = styled.button`
	width: ${(props) => (props.input ? "120px" : "140px")};
	height: ${(props) => (props.input ? "35px" : "40px")};
	background-color: ${(props) => (props.input ? props.theme.colors.mainText : props.theme.colors.third)};
	color: ${(props) => (props.input ? props.theme.colors.box : props.theme.colors.mainText)};
	border: 2px solid
		${(props) =>
			props.light
				? props.theme.colors.mainText
				: props.input
				? props.theme.colors.box
				: props.theme.colors.third};
	border-radius: ${(props) => props.theme.radius};
	transition: ${(props) => props.theme.transition};
	font-size: ${(props) => props.theme.fonts.xs};
	font-weight: 700;
	cursor: pointer;
	&:hover {
		color: ${(props) => (props.input ? props.theme.colors.mainText : props.theme.colors.third)};
		background-color: ${(props) => (props.input ? props.theme.colors.box : props.theme.colors.mainText)};
	}
	@media screen and (min-width: ${(props) => props.theme.breakpoints.medium}) {
		width: ${(props) => (props.input ? "100px" : "100px")};
		height: ${(props) => (props.input ? "30px" : "30px")};
		font-size: ${(props) => props.theme.fonts.s};
	}
	@media screen and (min-width: ${(props) => props.theme.breakpoints.xLarge}) {
		width: ${(props) => (props.input ? "90px" : "120px")};
		height: ${(props) => (props.input ? "35px" : "40px")};
		font-size: ${(props) => props.theme.fonts.s};
	}
`;

const Button = ({ name, type, id, onClick, light, input }) => {
	return (
		<StyledButton light={light} input={input} onClick={onClick} type={type} id={id}>
			{name}
		</StyledButton>
	);
};

export default Button;
